import React from "react"

const Hflogo = ({ width, className }) => {
  return (
    <svg width={width} className={className} viewBox="0 0 51 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.386 24.1865C29.2593 22.7363 30.0651 20.647 29.1859 19.5199C28.3066 18.3928 26.0753 18.6546 24.202 20.1047C22.3287 21.5548 21.5229 23.6441 22.4021 24.7713C23.2814 25.8984 25.5127 25.6366 27.386 24.1865Z" fill="white"/>
      <path d="M25.7939 22.241L0 0.384094L24.2921 2.14034L10.5134 4.93768L25.7939 22.241Z" fill="white"/>
      <path d="M25.2061 21.8835L51 43.7404L26.7079 41.9841L40.4866 39.1868L25.2061 21.8835Z" fill="white"/>
      <path d="M36.9325 0C24.736 0 9.81031 9.84987 3.59558 21.9997C-2.61915 34.1501 2.23046 43.9995 14.4274 43.9995C26.6244 43.9995 41.5496 34.1496 47.7643 21.9997C53.9791 9.84987 49.1295 0 36.9325 0ZM12.0807 15.8011C15.4213 12.057 20.3508 8.42365 25.5097 6.52254L24.8655 7.22494C18.4393 9.95189 12.4069 15.443 9.07705 21.9161C6.96997 26.0116 5.22799 32.3204 8.48922 35.6416C10.0955 37.5086 12.4059 38.3089 16.6518 38.3089L19.5249 40.0004C0.542233 41.9066 2.35642 26.6987 12.0807 15.8011ZM34.8705 22.1436C32.3164 27.1374 26.182 31.1855 21.1696 31.1855C16.1571 31.1855 14.1637 27.1374 16.7178 22.1436C19.2719 17.1498 25.4063 13.1017 30.4187 13.1017C35.4311 13.1017 37.4246 17.1498 34.8705 22.1436Z" fill="white"/>
    </svg>
  )
}

export default Hflogo